import { ApiResponse, ResponseMetadata } from '@/shared/types/generic';
import {
  AudienceType,
  PublicDisplay,
  PublicDisplaysResponse,
  PublicDisplaysSfxWidgetType,
  PublicDisplaysDataWidgetType,
} from '@/shared/types/publicDisplays';

const publicDisplaysList: PublicDisplay[] = [
  {
    name: 'pd1',
    description: 'desc-pd1',
    displayCode: '001',
    language: 'en-GB',
    activePin: true,
    layoutType: 'all_challenges',
    pin: '123456',
    audienceType: AudienceType.TEAM,
    audiences: ['team1'],
    link: 'public-display1',
    challenges: {
      enabled: true,
      finishChallengesPeriod: 864000,
      rotationDuration: 15,
    },
    metrics: {
      enabled: false,
      references: [],
      rotationDuration: 15,
    },
    hasConfettis: false,
    hasSound: false,
    shareOnly: false,
  },
  {
    name: 'um public display com um nome muitoooooooooooo grandeeeeeee e ainda maior que istooooooo',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris eget magna lacus. Aliquam vitae lacus odio. Praesent ve.',
    displayCode: '001',
    language: 'en-GB',
    activePin: false,
    layoutType: 'all_challenges',
    pin: '654321',
    audienceType: AudienceType.INDIVIDUAL,
    audiences: ['user1'],
    link: 'public-display2',
    challenges: {
      enabled: true,
      finishChallengesPeriod: 864000,
      rotationDuration: 15,
    },
    metrics: {
      enabled: true,
      references: ['metric-reference'],
      rotationDuration: 15,
    },
    hasConfettis: false,
    hasSound: false,
    shareOnly: false,
  },
];

const metadata: ResponseMetadata = {
  totalItems: 2,
  lastUpdated: new Date('2022-11-03T15:12:15.127247').getTime(),
};

export function mockGetPublicDisplays(): ApiResponse<PublicDisplaysResponse> {
  return {
    metadata,
    results: {
      link: 'generic.link',
      items: publicDisplaysList,
    },
  };
}

export const createPublicDisplay = () => {
  publicDisplaysList.push();
  return {
    language: 'PT_pt',
    results: publicDisplaysList,
  };
};

export const mockPublicDisplaysWidgets = {
  [PublicDisplaysDataWidgetType.CHALLENGES]: {
    type: PublicDisplaysDataWidgetType.CHALLENGES,
    expandable: false,
    disabled: false,
    active: false,
    title: 'challenges-title',
    tooltip: 'challenges-tooltip',
  },
  [PublicDisplaysDataWidgetType.METRICS]: {
    type: PublicDisplaysDataWidgetType.METRICS,
    expandable: true,
    disabled: false,
    active: true,
    title: 'metrics-title',
    tooltip: 'metrics-tooltip',
  },
};

export const mockPublicDisplaysSFXWidgets = {
  [PublicDisplaysSfxWidgetType.SOUNDS]: {
    type: PublicDisplaysSfxWidgetType.SOUNDS,
    expandable: false,
    disabled: false,
    active: false,
    title: 'metrics-title',
    tooltip: 'metrics-tooltip',
  },
  [PublicDisplaysSfxWidgetType.VISUALS]: {
    type: PublicDisplaysSfxWidgetType.VISUALS,
    expandable: true,
    disabled: false,
    active: false,
    title: 'metrics-title',
    tooltip: 'metrics-tooltip',
  },
};
