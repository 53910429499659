import { DEFAULT_ORGANIZATION_NAME } from '@/shared/constants/variables';

export default function useOrganizatonName() {
  function getOrganizationName(): string {
    return (
      localStorage.getItem('organizationName') ?? DEFAULT_ORGANIZATION_NAME
    );
  }

  function setOrganizationName(newOrganizationName: string): void {
    localStorage.setItem('organizationName', newOrganizationName);
  }

  return { getOrganizationName, setOrganizationName };
}
