import { defineStore } from 'pinia';
import profileService from '@/shared/services/profileService/profileService';
import {
  EditProfileRequestBody,
  LanguageCode,
  LockedOutResponse,
  ManagerProfile,
  ManagerProfileSite,
} from '@/shared/types/profile';
import { Locale } from '@/shared/types/generic';

interface State {
  userProfile?: ManagerProfile;
  userProfileSite?: ManagerProfileSite;
  languagesCodes: LanguageCode[];
  userLockedOutInfo: LockedOutResponse;
}

export default defineStore({
  id: 'profile',

  state: (): State => ({
    userProfile: undefined as ManagerProfile | undefined,
    userProfileSite: undefined as ManagerProfileSite | undefined,
    languagesCodes: [] as LanguageCode[],
    userLockedOutInfo: {} as LockedOutResponse,
  }),

  actions: {
    async loadProfile() {
      this.userProfile = await profileService.getProfile();
      if (
        this.userProfile.userConfig &&
        !this.userProfile.userConfig.userLanguage
      ) {
        this.userProfile.userConfig.userLanguage =
          this.userProfile.userConfig.language;
      }
    },

    async loadProfileSite(): Promise<void> {
      this.userProfileSite = await profileService.getProfileSite();
    },

    async editProfile(requestBody: EditProfileRequestBody): Promise<void> {
      return profileService.editProfile(requestBody);
    },

    isSelf(email: string): boolean {
      return this.userProfile?.user.email === email;
    },

    async updateShowFtx(showFtx: boolean): Promise<void> {
      try {
        await profileService.updateFtx({
          showFtx,
        });
      } finally {
        await this.loadProfile();
      }
    },

    async getLanguages() {
      try {
        this.languagesCodes = (
          await profileService.getLanguagesCodes()
        ).results;
      } catch (e) {
        this.languagesCodes = [];
      }
    },

    async saveProfileLanguage(language: Locale): Promise<void> {
      return profileService.saveProfileLanguage(language);
    },

    async loadUserLockedOutInfo(email: string): Promise<void> {
      try {
        this.userLockedOutInfo = await profileService.getUserLockedOutInfo(
          email,
        );
      } catch (e) {
        this.userLockedOutInfo = {} as LockedOutResponse;
      }
    },
  },
});
