export enum PermissionCode {
  DASHBOARD_READ = 'MD_001',
  CHALLENGE_CREATE = 'CH_001',
  CHALLENGE_EDIT = 'CH_002',
  CHALLENGE_DUPLICATE = 'CH_003',
  CHALLENGE_DELETE = 'CH_004',
  CHALLENGE_READ = 'CH_005',
  CHALLENGE_FINISH_NOW = 'CH_006',
  CHALLENGE_CANCEL = 'CH_007',
  LEADERBOARD_READ = 'LD_001',
  STORE_CREATE_PRIZE = 'ST_001',
  STORE_CREATE_CATEGORY = 'ST_002',
  STORE_READ_ITEMS = 'ST_003',
  STORE_EDIT_ITEMS = 'ST_004',
  STORE_DELETE_PRIZE = 'ST_005',
  STORE_DELETE_CATEGORY = 'ST_006',
  PURCHASE_ACTIVITY_READ = 'PA_001',
  PURCHASE_ACTIVITY_REFUND = 'PA_002',
  USERS_CREATE = 'US_001',
  USERS_READ = 'US_002',
  USERS_EDIT = 'US_003',
  USERS_DELETE = 'US_004',
  TEAMS_CREATE = 'TM_001',
  TEAMS_EDIT = 'TM_002',
  TEAMS_ADD_USERS = 'TM_003',
  TEAMS_REMOVE = 'TM_004',
  TEAMS_READ = 'TM_005',
  TEAMS_UPLOAD_AVATAR = 'TM_006',
  METRICS_CREATE = 'MT_001',
  METRICS_READ = 'MT_002',
  METRICS_EDIT = 'MT_003',
  METRICS_DELETE = 'MT_004',
  METRICS_ADD_METRICS_TO_CATEGORY = 'MT_005',
  CATEGORY_CREATE = 'CT_001',
  CATEGORY_READ = 'CT_002',
  CATEGORY_EDIT = 'CT_003',
  CATEGORY_DELETE = 'CT_004',
  DATA_IMPORT_CREATE = 'DI_001',
  DATA_IMPORT_DELETE = 'DI_002',
  DATA_IMPORT_EXPORT = 'DI_003',
  DATA_IMPORT_READ = 'DI_004',
  PUBLIC_DISPLAYS_READ = 'PD_001',
  SURVEYS_CREATE = 'SV_001',
  SURVEYS_EDIT = 'SV_002',
  SURVEYS_DELETE = 'SV_003',
  SURVEYS_CANCEL = 'SV_004',
  SURVEYS_READ = 'SV_005',
  SITES_CREATE = 'OS_001',
  SITES_EDIT = 'OS_002',
  SITES_DELETE = 'OS_003',
  SITES_READ = 'OS_004',
  ROLES_CREATE_CUSTOM = 'RP_001',
  ROLES_EDIT_PERMISSONS = 'RP_002',
  ROLES_ADD_USERS_TO_ROLES = 'RP_003',
  ROLES_READ = 'RP_004',
  ROLES_DELETE = 'RP_005',
  INTEGRATION_READ = 'IN_001',
  INTEGRATION_CREATE = 'IN_002',
  INTEGRATION_EDIT = 'IN_003',
  INTEGRATION_DELETE = 'IN_004',
}

export enum RoleLevelTypeStaticReference {
  DEFAULT = 'DEFAULT',
  CUSTOM = 'CUSTOM',
}

export enum RoleLevelType {
  MANAGER = 'manager',
  SUPER_MANAGER = 'superManager',
  READ_ONLY = 'readOnly',
}

export interface RoleLevelSimplified {
  name: string;
  description: string;
  reference: string;
}

export interface RoleLevelsSimplified {
  defaultRoleLevels: RoleLevelSimplified[];
  customRoleLevels: RoleLevelSimplified[];
}

export interface GetRoleLevelsSimplified {
  defaultRoles: RoleLevelSimplified[];
  customRoles: RoleLevelSimplified[];
}

export interface ProfileRoleLevelResponse {
  name: string;
  reference: string;
  activePermissionsCodes: PermissionCode[];
}

export interface ProfileRoleLevel {
  name: string;
  reference: string;
  activePermissionsCodes: Record<PermissionCode, boolean>;
}

export interface PermissionTypeCount {
  permissionTypeStaticReference: string;
  activePermissionCount: number;
}

export interface OrgRoleLevel {
  roleLevelName: string;
  roleLevelDescription: string;
  roleLevelReference: string;
  permissionsTypeCount: PermissionTypeCount[];
  numberOfManagers: number;
  roleLevelTypeStaticReference: RoleLevelTypeStaticReference;
}

export interface OrgRoleLevelsResponse {
  rolesPermissions: OrgRoleLevel[];
}

export interface RoleLevelsCount {
  numberOfRoleLevels: number;
}

export interface AddRoleErrors {
  name: string;
}

export interface PermissionRequest {
  active: boolean;
  code: string;
}

export interface AddCustomRoleLevelRequest {
  name: string;
  description: string;
  permissions: PermissionRequest[];
}

export interface EditCustomRoleLevelRequest {
  name: string;
  description: string;
  reference: string;
  permissions: PermissionRequest[];
}
