import { defineStore } from 'pinia';
import { RouteLocationNormalized } from 'vue-router';

interface State {
  loaderState: boolean;
  loaderTarget: string;
  loaderTransparent: boolean;
  sharedConfirmationModal: boolean;
  confirmationModalTitle: string;
  confirmationModalDescription: string;
  confirmationModalPath: string;
  loaderText: string;
  confirmationModalDiscardLabel: string;
  confirmationModalCancelLabel: string;
  notify?: VoidFunction;
}

export default defineStore({
  id: 'shared',

  state: (): State => ({
    loaderState: false,
    loaderTarget: '',
    loaderTransparent: false,
    sharedConfirmationModal: false,
    confirmationModalTitle: '',
    confirmationModalDescription: '',
    confirmationModalPath: '',
    loaderText: '',
    confirmationModalDiscardLabel: '',
    confirmationModalCancelLabel: '',
    notify: undefined,
  }),

  actions: {
    showConfirmationModal(): void {
      this.sharedConfirmationModal = true;
    },

    saveNotify(notification: VoidFunction): void {
      this.notify = notification;
    },

    handlerBeforeRouteLeave(
      title: string,
      description: string,
      to: RouteLocationNormalized,
      next: (param?: boolean) => void,
      cancelLabel = '',
      discardLabel = '',
    ): void {
      if (
        to.query?.bypass === 'true' ||
        to.redirectedFrom?.query?.bypass === 'true'
      ) {
        next();
      } else {
        this.confirmationModalTitle = title;
        this.confirmationModalDescription = description;
        this.confirmationModalCancelLabel = cancelLabel;
        this.confirmationModalDiscardLabel = discardLabel;
        this.confirmationModalPath = to.path;
        this.showConfirmationModal();
        next(false);
      }
    },

    setLoader(
      loaderState: boolean,
      loaderTarget?: string,
      loaderTransparent = false,
      loaderText = '',
    ) {
      this.loaderState = loaderState;
      this.loaderTarget = loaderTarget || '';
      this.loaderTransparent = loaderTransparent;
      this.loaderText = loaderText;
    },
  },
});
