import i18n from '@/plugins/i18n/i18n';
import { DriveElements, FTXDriveStep } from '@/shared/types/ftx';
import { Feature } from '@/shared/types/generic';
import { PermissionCode } from '@/shared/types/roles';

const ftxSections: FTXDriveStep[] = [
  {
    element: '#vaibe-ftx__product-tour',
    popover: {
      title: i18n.global.t('ftx.joyride.tour.title'),
      description: i18n.global.t('ftx.joyride.tour.description'),
      side: 'right',
      align: 'start',
      showProgress: false,
      nextBtnText: i18n.global.t('ftx.joyride.buttons.letsGo'),
      prevBtnText: i18n.global.t('ftx.joyride.buttons.dismiss'),
      disableButtons: ['close'],
      showButtons: ['next', 'previous'],
    },
  },
  {
    element: `#vaibe-ftx__${DriveElements.HOME}`,
    feature: Feature.DASHBOARD_MANAGER,
    permissions: [PermissionCode.DASHBOARD_READ],
    popover: {
      title: i18n.global.t('ftx.joyride.home.title'),
      description: i18n.global.t('ftx.joyride.home.description'),
      side: 'right',
      align: 'start',
      showProgress: true,
    },
  },
  {
    element: `#vaibe-ftx__${DriveElements.CHALLENGES}`,
    permissions: [PermissionCode.CHALLENGE_READ],
    popover: {
      title: i18n.global.t('ftx.joyride.challenges.title'),
      description: i18n.global.t('ftx.joyride.challenges.description'),
      side: 'right',
      align: 'start',
      showProgress: true,
    },
  },
  {
    element: `#vaibe-ftx__${DriveElements.LEADERBOARD}`,
    permissions: [PermissionCode.LEADERBOARD_READ],
    popover: {
      title: i18n.global.t('ftx.joyride.leaderboard.title'),
      description: i18n.global.t('ftx.joyride.leaderboard.description'),
      showProgress: true,
      side: 'right',
      align: 'start',
    },
  },
  {
    element: `#vaibe-ftx__${DriveElements.USERS}`,
    permissions: [PermissionCode.USERS_READ, PermissionCode.TEAMS_READ],
    popover: {
      title: i18n.global.t('ftx.joyride.users.title'),
      description: i18n.global.t('ftx.joyride.users.description'),
      showProgress: true,
      side: 'right',
      align: 'start',
    },
  },
  {
    element: `#vaibe-ftx__${DriveElements.METRICS}`,
    permissions: [PermissionCode.METRICS_READ, PermissionCode.CATEGORY_READ],
    popover: {
      title: i18n.global.t('ftx.joyride.metrics.title'),
      description: i18n.global.t('ftx.joyride.metrics.description'),
      showProgress: true,
      side: 'right',
      align: 'start',
    },
  },
  {
    element: `#vaibe-ftx__${DriveElements.STORE}`,
    permissions: [
      PermissionCode.STORE_READ_ITEMS,
      PermissionCode.PURCHASE_ACTIVITY_READ,
    ],
    popover: {
      title: i18n.global.t('ftx.joyride.store.title'),
      description: i18n.global.t('ftx.joyride.store.description'),
      showProgress: true,
      side: 'right',
      align: 'start',
    },
  },
  {
    element: `#vaibe-ftx__${DriveElements.SURVEYS}`,
    feature: Feature.SURVEY_DEFAULT_QUESTIONS,
    permissions: [PermissionCode.SURVEYS_READ],
    popover: {
      title: i18n.global.t('ftx.joyride.surveys.title'),
      description: i18n.global.t('ftx.joyride.surveys.description'),
      showProgress: true,
      side: 'right',
      align: 'start',
    },
  },
  {
    element: `#vaibe-ftx__${DriveElements.PUBLIC_DISPLAYS}`,
    feature: Feature.PUBLIC_DISPLAY_MANAGER,
    permissions: [PermissionCode.PUBLIC_DISPLAYS_READ],
    popover: {
      title: i18n.global.t('ftx.joyride.publicDisplays.title'),
      description: i18n.global.t('ftx.joyride.publicDisplays.description'),
      showProgress: true,
      side: 'right',
      align: 'start',
    },
  },
  {
    element: `#vaibe-ftx__${DriveElements.SETTINGS}`,
    permissions: [PermissionCode.SITES_READ],
    popover: {
      title: i18n.global.t('ftx.joyride.settings.title'),
      description: i18n.global.t('ftx.joyride.settings.description'),
      showProgress: true,
      side: 'right',
      align: 'start',
    },
  },
  {
    element: `#vaibe-ftx__${DriveElements.PROFILE}`,
    popover: {
      title: i18n.global.t('ftx.joyride.profile.title'),
      description: i18n.global.t('ftx.joyride.profile.description'),
      showProgress: true,
      side: 'right',
      align: 'start',
    },
  },
];

export default ftxSections;
