import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import AppAvatar from '@/components/app/AppAvatar/AppAvatar.vue';
import { GRAY_50 } from '@/shared/constants/colors';
import getInitials from '@/shared/helpers/getInitials/getInitials';

interface Props {
  name: string;
  size?: string;
  backgroundColor?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ManagerAvatar',
  props: {
    name: {},
    size: { default: '' },
    backgroundColor: { default: GRAY_50 }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AppAvatar, {
    style: _normalizeStyle({ backgroundColor: _ctx.backgroundColor }),
    size: _ctx.size
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_unref(getInitials)(_ctx.name)), 1)
    ]),
    _: 1
  }, 8, ["style", "size"]))
}
}

})