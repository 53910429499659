import useTokenStore from '@/store/token/useTokenStore';
import { useRouter } from 'vue-router';
import { Locale } from '@/shared/types/generic';
import { updateCommonsLocale } from '@vaibe-github-enterprise/kd-kse-fe-commons';
import i18n from '@/plugins/i18n/i18n';
import useErrorStore from '@/store/error/useErrorStore';
import useKudosStore from '@/store/kudos/useKudosStore';
import useProfileStore from '@/store/profile/useProfileStore';
import useSharedStore from '@/store/shared/useSharedStore';
import useStoreStore from '@/store/store/useStoreStore';
import useSurveysStore from '@/store/surveys/useSurveysStore';
import useTranslationsStore from '@/store/translations/useTranslationsStore';
import useDashboardStore from '@/store/dashboard/useDashboardStore';
import useFilesStore from '@/store/files/useFilesStore';
import useChallengesStore from '@/store/challenges/useChallengesStore';
import useImagesStore from '@/store/images/useImagesStore';
import useLeaderboardStore from '@/store/leaderboard/useLeaderboardStore';
import useMetricsStore from '@/store/metrics/useMetricsStore';
import usePublicDisplaysStore from '@/store/publicDisplays/usePublicDisplaysStore';
import useSitesStore from '@/store/settings/useSitesStore';
import useStatusStore from '@/store/status/useStatusStore';
import useUserStore from '@/store/user/useUserStore';
import { useFeatures } from '@/plugins/commons';
import useNavMenuData from '../useNavMenuData/useNavMenuData';
import useOrganizatonName from '../useOrganizationName/useOrganizationName';

export default function useLogout() {
  const router = useRouter();
  const { getOrganizationName, setOrganizationName } = useOrganizatonName();

  function resetStores(): void {
    useDashboardStore().$reset();
    useErrorStore().$reset();
    useFilesStore().$reset();
    useChallengesStore().$reset();
    useImagesStore().$reset();
    useKudosStore().$reset();
    useLeaderboardStore().$reset();
    useMetricsStore().$reset();
    useProfileStore().$reset();
    usePublicDisplaysStore().$reset();
    useSitesStore().$reset();
    useStatusStore().$reset();
    useStoreStore().$reset();
    useSurveysStore().$reset();
    useUserStore().$reset();
    useFeatures().resetFeatures();
    useNavMenuData().$reset();
  }

  async function logout(): Promise<void> {
    const organizationName: string = getOrganizationName();

    resetStores();
    useTokenStore().$reset();
    useTranslationsStore().$reset();
    useSharedStore().$reset();

    localStorage.clear();

    setOrganizationName(organizationName);

    i18n.global.locale.value = process.env.VUE_APP_I18N_LOCALE || Locale.US;

    updateCommonsLocale(process.env.VUE_APP_I18N_LOCALE || Locale.US);

    await router.push({
      path: '/login',
      query: { bypass: 'true' },
    });
  }

  return {
    logout,
    resetStores,
  };
}
