export const MAXIMUM_LEADERBOARD_TABLE_POSITION = 99999;
export const LEADERBOARD_TOOLTIP_MINMUM_LENGTH = 13;
export const INFINITE_SCROLL_PAGE_SIZE = 5;
export const INFINITE_SCROLL_MODAL_PAGE_SIZE = 12;
export const GRAPH_HEIGHT_MINIZED = 232;
export const FINISHED_CHALLENGES_DISPLAY_PERIOD = 24;
export const PUBLIC_DISPLAYS_NAME_MAX_LENGTH = 32;
export const PUBLIC_DISPLAYS_DESCRIPTION_MAX_LENGTH = 120;
export const TRANSITION_TIME = 30;
export const TEAM_NAME_ERROR_CODE = 19000;
export const ORG_TEAM_ID_ERROR_CODE = 19002;
export const TEAM_UPLOADED_IMAGE_MAX_SIZE = 500_000;
export const DEFAULT_ORGANIZATION_NAME = 'vaibe';
export const STORE_DESCRIPTION_MAX_LENGTH = 120;
export const STORE_NAME_MAX_LENGTH = 54;
export const STORE_FILE_MAX_SIZE_BYTES = 500000;
