import axios, { InternalAxiosRequestConfig } from 'axios';
import { BusinessError, ResponseError } from '@/shared/types/utils';
import useErrorStore from '@/store/error/useErrorStore';
import { HttpErrorCodes } from '@/shared/types/generic';
import usePostHogEvents from '@/composables/usePostHog/usePostHogEvents';
import useTokenStore from '@/store/token/useTokenStore';
import { useAbortController } from '@/plugins/commons';

function decodeJWT(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

api.interceptors.request.use(
  async (
    request: InternalAxiosRequestConfig,
  ): Promise<InternalAxiosRequestConfig> => {
    const { setAbortController } = useAbortController();

    const { postHogSessionId } = usePostHogEvents();

    const tokenStore = useTokenStore();

    const abortController = new AbortController();

    setAbortController(abortController);

    if (tokenStore.token) {
      try {
        const jwt = decodeJWT(tokenStore.token);
        if (jwt.exp * 1000 < Date.now()) {
          await tokenStore.refreshUserToken();
        }
      } catch (e) {
        tokenStore.$reset();
      }
    }

    const requestConfig: InternalAxiosRequestConfig = {
      ...request,
      signal: abortController.signal,
    };

    requestConfig.headers.set('Source-Id', '2');

    requestConfig.headers.set('Time', Date.now());

    requestConfig.headers.set('Authorization', `Bearer ${tokenStore.token}`);

    requestConfig.headers.set(
      'K-Engage-Manager-Version',
      process.env.VUE_APP_VERSION,
    );

    requestConfig.headers.set('User-Id', tokenStore.b2cId);

    requestConfig.headers.set('Session-Id', postHogSessionId());

    return requestConfig;
  },
);

api.interceptors.response.use(
  (response) => response.data,
  (error) => {
    const errorStore = useErrorStore();

    errorStore.setErrorCode(error.response?.status);

    if (error.response?.status === HttpErrorCodes.LOCKED_USER) {
      errorStore.setLockErrorMessage(error.response.data);
    }

    const unauthorizedAccess =
      error.response?.status === HttpErrorCodes.FORBIDDEN ||
      error.response?.status === HttpErrorCodes.INACTIVE_USER ||
      error.response?.status === HttpErrorCodes.UNAUTHORIZED_USER ||
      error.response?.status === HttpErrorCodes.LOCKED_USER;

    const isBusinessError =
      axios.isAxiosError(error) &&
      ResponseError.isABusinessError(error.response?.data);

    if (isBusinessError && !unauthorizedAccess) {
      throw new ResponseError(
        error.response?.status || 400,
        error.response?.data as BusinessError,
      );
    }

    throw error;
  },
);

export default api;
