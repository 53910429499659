<template>
  <q-layout view="lHh lpR fFf">
    <q-drawer
      v-model="leftDrawer"
      show-if-above
      :width="257"
      :breakpoint="0"
      :mini="miniState"
      :mini-width="64"
    >
      <div class="vaibe-logo">
        <router-link to="/">
          <img
            v-if="!miniState"
            :src="getLogo()"
            alt="Vaibe"
            class="vaibe-logo--normal"
          />
          <img
            v-else
            :src="getFavicon()"
            alt="Vaibe"
            class="vaibe-logo--small"
          />
        </router-link>
      </div>
      <nav class="main-nav">
        <div>
          <router-link
            v-for="(link, i) in navigationLinks"
            :id="link.id"
            :key="i"
            :to="link.to"
            :data-testid="link.testId"
            active-class="main-nav__link--active"
            class="main-nav__link"
          >
            <div class="main-nav__link-icon">
              <component :is="link.icon" />
            </div>
            <div class="main-nav__link-label">{{ t(link.labelKey) }}</div>
          </router-link>
        </div>

        <div>
          <router-link
            v-if="isFTXEnabled"
            id="vaibe-ftx__product-tour"
            to=""
            data-testid="nav-settings-link"
            class="main-nav__link"
            @click="startJoyride"
            @keypress="startJoyride"
          >
            <div class="main-nav__link-icon">
              <ProductTourNav class="app-layout-nav-icon" />
            </div>
            <div class="main-nav__link-label">{{ t('nav.productTour') }}</div>
          </router-link>

          <router-link
            v-if="showSettings"
            id="vaibe-ftx__settings"
            to="/settings"
            data-testid="nav-settings-link"
            active-class="main-nav__link--active"
            class="main-nav__link"
          >
            <div class="main-nav__link-icon">
              <SettingsNav class="app-layout-nav-icon" />
            </div>
            <div class="main-nav__link-label">{{ t('nav.settings') }}</div>
          </router-link>

          <router-link
            id="vaibe-ftx__profile"
            to="/account-settings"
            :class="[
              'main-nav__link',
              {
                'main-nav__link--active': isProfileSelected,
              },
            ]"
          >
            <div class="main-nav__link-icon">
              <ManagerAvatar :name="name" size="32px" font-size="12px" />
            </div>
            <div class="main-nav__link-label">
              <span>{{ name }}</span>
            </div>
          </router-link>
        </div>
      </nav>
    </q-drawer>
    <q-page-container>
      <div class="app-layout-page__content">
        <slot />
      </div>
    </q-page-container>
  </q-layout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mdiChevronRight } from '@quasar/extras/mdi-v6';
import { mapStores } from 'pinia';
import AppIcon from '@/components/app/AppIcon/AppIcon.vue';
import useProfileStore from '@/store/profile/useProfileStore';
import useTokenStore from '@/store/token/useTokenStore';
import { vaibeLogo, book, vaibeLogoSmall } from '@/shared/constants/icons';
import useDriver from '@/composables/useDriverJs/useDriverJs';
import { Feature, Routes } from '@/shared/types/generic';
import { useFeatures, useTheme } from '@/plugins/commons';
import AppAvatar from '@/components/app/AppAvatar/AppAvatar.vue';
import AppDropdownMenu from '@/components/app/AppDropdownMenu/AppDropdownMenu.vue';
import SettingsNav from '@icons/icon-settings.vue';
import ProductTourNav from '@icons/icon-product-tour.vue';
import usePostHogEvents from '@/composables/usePostHog/usePostHogEvents';
import { PostHogEvents } from '@/shared/types/posthog';
import { PermissionCode } from '@/shared/types/roles';
import useRolesStore from '@/store/roles/useRolesStore';
import useNavMenuData from '@/composables/useNavMenuData/useNavMenuData';
import { useI18n } from 'vue-i18n';
import { useQuasar } from 'quasar';
import ManagerAvatar from '@/components/user/ManagerAvatar/ManagerAvatar.vue';

export default defineComponent({
  name: 'AppLayout',

  components: {
    AppIcon,
    AppAvatar,
    AppDropdownMenu,
    SettingsNav,
    ProductTourNav,
    ManagerAvatar,
  },

  setup() {
    const { isFeatureEnabled } = useFeatures();
    const { navigationLinks, driverSteps } = useNavMenuData();
    const { getLogo, getFavicon } = useTheme();
    const { t } = useI18n();
    const { screen } = useQuasar();

    return {
      t,
      screen,
      isFeatureEnabled,
      navigationLinks,
      driverSteps,
      getLogo,
      getFavicon,
    };
  },

  data() {
    return {
      leftDrawer: true,
      book,
      mdiChevronRight,
      Feature,
      vaibeLogo,
      vaibeLogoSmall,
    };
  },

  computed: {
    ...mapStores(useProfileStore, useTokenStore, useRolesStore),

    name(): string | undefined {
      return this.profileStore.userProfile?.user.name;
    },

    miniState(): boolean {
      return (
        ['xs', 'sm'].includes(this.screen.name) ||
        (this.$route.meta.miniMode as boolean)
      );
    },

    isProfileSelected(): boolean {
      return this.$router.currentRoute.value.path.includes(
        Routes.ACCOUNT_SETTINGS,
      );
    },

    isFTXEnabled(): boolean {
      return this.isFeatureEnabled(Feature.FTX);
    },

    showSettings(): boolean {
      return (
        this.rolesStore.isPermissionActive(PermissionCode.SITES_READ) ||
        this.rolesStore.isPermissionActive(PermissionCode.ROLES_READ)
      );
    },
  },

  methods: {
    startJoyride() {
      usePostHogEvents().postHogEvent(PostHogEvents.FTX_REVISIT_TOUR_MANAGER);

      const driverJsJoyrideButtons = {
        next: this.t('labels.common.next'),
        previous: this.t('labels.common.previous'),
        done: this.t('labels.common.finish'),
      };

      useDriver(this.driverSteps, driverJsJoyrideButtons);
    },
  },
});
</script>

<style scoped lang="scss">
.vaibe-logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 64px;
  padding-top: 12px;
  margin-left: 52px;
  line-height: 0;
  transition: all 0.3s;
  position: relative;
}

.vaibe-logo--normal {
  max-width: 160px;
  max-height: 64px;
  mix-blend-mode: darken;
  object-fit: contain;
}

.vaibe-logo--small {
  width: 32px;
  height: 32px;
  mix-blend-mode: darken;
  object-fit: contain;
}

.q-header {
  background: $white;
  height: 64px;
  padding: 0;
  align-items: flex-start;
  border-bottom-left-radius: 8px;
  box-shadow: 0 2px 12px rgba(38, 37, 35, 0.12);
}

.q-header__top-border {
  background: linear-gradient(232.81deg, #982aa2 -0.62%, #1659f2 97.92%);
  width: 100%;
  height: 4px;
}

.main-nav {
  margin: 24px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.main-nav__link {
  display: flex;
  align-items: center;
  margin: 0 32px 4px;
  height: 42px;
  border-radius: 8px;
  color: $gray-800;
  position: relative;
  text-decoration: none;
}

.main-nav__link::before {
  content: '';
  background: transparent;
  width: 3px;
  height: 100%;
  position: absolute;
  left: -33px;
}

.main-nav__link:hover {
  background: $primary-background;
}

.main-nav__link--active {
  background: $primary-background;
  color: $primary-color;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.main-nav__link:hover::before,
.main-nav__link--active::before {
  background: $primary-color;
  color: $primary-color;
}

.main-nav__link-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;
  width: 64px;
  color: $gray-700;
}

.main-nav__link--active .main-nav__link-icon,
.main-nav__link--active .main-nav__link-icon svg :deep(path) {
  color: $primary-color;
  fill: $primary-color;
}

.q-drawer--mini {
  .vaibe-logo {
    margin-left: 16px;
  }

  .main-nav__link {
    margin: 0 0 8px 3px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .main-nav__link::before {
    left: -3px;
  }

  .main-nav__link-label {
    font-size: 15px;
    display: none;
    cursor: pointer;
  }
}

.main-nav__link-label {
  cursor: pointer;
}

.q-header__content {
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 60px;
}

.app-layout__header-content {
  display: flex;
}

.app-layout__container__product-tour {
  height: 32px;
  width: 32px;
  display: flex;
}
.app-layout__product-tour {
  width: 100%;
  cursor: pointer;
}

.profile-dropdown__avatar {
  background: $white;
  cursor: pointer;
  font-weight: 500;
  color: $gray-800;
  width: 24px;
  height: 24px;
}

.profile-dropdown__avatar--active {
  color: $primary-color;
}

.main-nav__link-icon :deep(.q-avatar__content) {
  font-size: 10px;
}

.app-layout-dropdown-menu__profile {
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.app-layout-dropdown__option {
  padding: 8px;
}

.app-layout-dropdown__option:hover {
  background: $gray-25;
  color: $gray-800;
}

.main-nav__link-icon svg :deep(path) {
  fill: currentColor;
}

.app-layout-nav-icon {
  width: 18px;
  height: 18px;
}

.q-layout {
  display: flex;
}

.q-page-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

.app-layout-page__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 100vh;
}

.app-layout-page__content > :deep(main) {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}

:deep(.q-drawer__content) {
  display: flex;
  flex-direction: column;
}
</style>
