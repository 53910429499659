export interface PinInformation {
  pin: string;
  orgName: string;
  ingress: string;
  activePin: boolean;
}

export enum AudienceType {
  INDIVIDUAL = 'individual',
  TEAM = 'team',
}

export interface PublicDisplay {
  name: string;
  description: string;
  displayCode: string;
  activePin: boolean;
  language: string;
  layoutType: string;
  link: string;
  pin: string;
  audiences: string[];
  audienceType?: AudienceType;
  challenges: {
    enabled: boolean;
    finishChallengesPeriod: number;
    rotationDuration: number;
  };
  metrics: {
    enabled: boolean;
    references: string[];
    rotationDuration: number;
  };
  hasConfettis: boolean;
  hasSound: boolean;
  shareOnly: boolean;
}

export enum PublicDisplaysDataWidgetType {
  CHALLENGES = 'challenges',
  METRICS = 'metrics',
}

export enum PublicDisplaysSfxWidgetType {
  VISUALS = 'visuals',
  SOUNDS = 'sounds',
}

export interface PublicDisplaysWidget {
  type: PublicDisplaysDataWidgetType | PublicDisplaysSfxWidgetType;
  expandable: boolean;
  disabled: boolean;
  title: string;
  tooltip: string;
}

export interface PublicDisplaysDataWidgetsModel {
  challenges: {
    enabled: boolean;
    finishChallengesPeriod: number;
    rotationDuration: number;
  };
  metrics: {
    enabled: boolean;
    references: string[];
    rotationDuration: number;
  };
}

export interface PublicDisplaysSfxWidgetsModel {
  hasConfettis: boolean;
  hasSound: boolean;
}

export interface PublicDisplaysWidgetsErrors {
  dataWidgets: string;
  metrics: string;
}

export interface PublicDisplaysAddRequest
  extends PublicDisplaysDataWidgetsModel,
    PublicDisplaysSfxWidgetsModel {
  audienceType: AudienceType;
  audienceReferences: string[];
  isPinProtected: boolean;
  name: string;
  description?: string;
  language: string;
}

export enum ShareType {
  GENERIC = 'generic',
  SPECIFIC = 'specific',
}

export interface PublicDisplaysResponse {
  link: string;
  items: PublicDisplay[];
}

export interface LanguagesList {
  label: string;
  code: string;
}

export interface PublicDisplaysEditRequest extends PublicDisplaysAddRequest {
  pin: string;
}

export interface PublicDisplaysEditFormModel extends PublicDisplaysAddRequest {
  pin: number[];
  activePin: boolean;
}

export interface PublicDisplaysAddErrors extends PublicDisplaysWidgetsErrors {
  name: string;
  audience: string;
}

export interface PublicDisplaysEditErrors extends PublicDisplaysWidgetsErrors {
  name: string;
  audience: string;
  pinErrorMessage: string;
  pin: string[];
}
