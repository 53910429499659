import {
  BLUE_600,
  GREEN_700,
  ORANGE_600,
  PINK_600,
  PURPLE_500,
  VIOLET_600,
  YELLOW_800,
} from '@/shared/constants/colors';
import { MetricsDropdownOption } from './components';
import {
  ChallengeAudience,
  ChallengeAudienceStaticReference,
  ChallengeGoalStaticReference,
  ChallengeMode,
  ChallengeModeStaticReference,
  ChallengeType,
  ChallengeTypeStaticReference,
  GameDetails,
  ChallengeStatus,
} from './challenges';
import { ProcessCompletionDetails, Image, IBase } from './generic';
import { CalculationMethod, UnitOfMeasure } from './metrics';

export enum WizardDurationPreset {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  CUSTOM = 'custom',
}

export interface WizardDurationModel {
  startDate: Date;
  endDate: Date;
  preset: WizardDurationPreset;
}
export enum ChallengeWizardStep {
  FORMAT = 0,
  PARTICIPANTS_GOAL = 1,
  DURATION = 2,
  PRIZE = 3,
  SUMMARY = 4,
}

export enum ChallengeFormat {
  COMPETITION = 'CM_1.CT_3',
  RACE = 'CM_1.CT_4',
  COOPERATION = 'CM_3.CT_5',
  SELF_IMPROVEMENT = 'CM_7.CT_6',
  TEAM_COMPETITION = 'CM_5.CT_3',
  TEAM_RACE = 'CM_5.CT_4',
  TEAM_COOPERATION = 'CM_6.CT_5',
}

export interface ChallengeModeType {
  name: string;
  staticReference: ChallengeFormat;
  image: {
    url: string;
    default: boolean;
  };
  challengeMode: {
    staticReference: ChallengeModeStaticReference;
  };
  challengeType: {
    staticReference: ChallengeTypeStaticReference;
  };
  enabled: boolean;
  defaultSelection: boolean;
}

export interface ChallengeFormatOptions {
  audience: {
    name: string;
    staticReference: string;
  };
  challengeModeTypes: ChallengeModeType[];
}

export interface ChallengeForm {
  format: ChallengeFormat;
  audience: ChallengeAudienceStaticReference;
  participants: string[];
  metric?: MetricsDropdownOption;
  amount?: number;
  unit: string;
  goal?: string;
  participantsAvailable: number;
  startDate: Date;
  endDate: Date;
  preset: WizardDurationPreset;
  coins: number;
  recurrencyConfig?: string;
  name: string;
  description?: string;
  image: string;
  maxCoins: number;
  calculationType: string;
  goalStatement: string;
  totalUsersInTeams: number;
}

export interface ChallengeImage {
  reference: string;
  imageUrl: string;
}

export interface ChallengeMetric {
  reference: string;
  name: string;
}

export interface ChallengeGoal {
  name: string;
  staticReference: ChallengeGoalStaticReference;
}

export interface IChallenge extends IBase, Partial<GameDetails> {
  category?: ChallengeMetric;
  description: string;
  duration: number;
  endTime: number;
  goal?: ChallengeGoal;
  id: number;
  illustration: Image;
  metric?: ChallengeMetric;
  mode: ChallengeMode;
  audience: ChallengeAudience;
  name: string;
  startTime: number;
  statusStaticReference: ChallengeStatus;
  unitMeasure: UnitOfMeasure;
  calculationType?: CalculationMethod;
  targetAmount?: number;
  type: ChallengeType;
  participationCoins: number;
  participationPoints: number;
  targetAmountReached: number;
  minimumAmountToReach: number;
  metricCoinRatio?: number;
  realStartTime: number;
  realEndTime: number;
  hasMultipleTimezones: boolean;
  forcedCompleted?: boolean;
  completionDetails?: ProcessCompletionDetails;
  modeType: {
    staticReference: ChallengeFormat;
    name: string;
  };
  forcedCompletionDate?: number;
  originalEndDate?: number;
  goalStatement: string;
}

export const challengeFormatColor = {
  [ChallengeFormat.COMPETITION]: BLUE_600,
  [ChallengeFormat.RACE]: VIOLET_600,
  [ChallengeFormat.COOPERATION]: ORANGE_600,
  [ChallengeFormat.SELF_IMPROVEMENT]: GREEN_700,
  [ChallengeFormat.TEAM_COMPETITION]: PINK_600,
  [ChallengeFormat.TEAM_RACE]: PURPLE_500,
  [ChallengeFormat.TEAM_COOPERATION]: YELLOW_800,
};

// export interface IChallenge extends Challenge {}

export interface LoadChallengeResponse {
  challenges: IChallenge[];
  numberOfChallenges: number;
  numberOfFilteredChallenges: number;
  numberOfFinished: number;
  numberOfOnGoing: number;
  numberOfScheduled: number;
  numberOfCurrent: number;
  numberOfClosed: number;
}
