import { Feature } from '@/shared/types/generic';
import { useFeatures } from '@/plugins/commons';
import { FTXPage, FTXSlide } from '@/shared/types/ftx';

export default function useFTXList(slidesContent: FTXSlide[]) {
  const featureToggles = useFeatures();

  const findSurveysPosition = slidesContent.findIndex(
    (slide) => slide.key === FTXPage.SURVEYS,
  );

  if (
    !featureToggles.isFeatureEnabled(Feature.SURVEY_DEFAULT_QUESTIONS) &&
    findSurveysPosition > 0
  ) {
    slidesContent.splice(findSurveysPosition, 1);
  }

  return {
    slidesContent,
  };
}
